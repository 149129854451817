import React, { useEffect, useState } from "react"
import { Row, Col, Dropdown, DropdownButton, ButtonGroup, Button, Form, InputGroup } from 'react-bootstrap';

import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchField from "./SearchField.js";


export default function FilterJobs({ onFilterChange, logado, children }) {

    const [cargaHorária, setCargaHorária] = useState();
    const [dataAnúncio, setDataAnuncio] = useState();
    const [tipo, setTipo] = useState();
    const [nível, setNível] = useState();
    const [onde, setOnde] = useState();
    const [salvos, setSalvos] = useState(false);

    const [cargo, setCargo] = useState();

    function handleFilterChange(name, value){
        const setters = {
            cargaHorária: setCargaHorária,
            dataAnúncio: setDataAnuncio,
            nível: setNível,
            tipo: setTipo,
            cargo: setCargo,
            salvos: setSalvos
        };

        if (setters[name]) {
            setters[name](value);
        }
    }

    let timeoutId;

    function handleSearchChange(value){
        setOnde(value);
    }

    function handleCargoChange(value){
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            setCargo(value);
        }, 500); // Adjust the debounce delay as needed (in milliseconds)
    }

    function getNomeNível(nível){
        var nomeMap = {
            1: "Ensino médio",
            2: "Ensino superior"
        }
        return nomeMap[nível];
    }

    useEffect(() => {
        onFilterChange({"cargaHorária": cargaHorária, "dataAnúncio": dataAnúncio, "nível": nível, "onde": onde, "tipo": tipo, "cargo": cargo, "salvos": salvos});
    }, [cargaHorária, dataAnúncio, nível, onde, tipo, cargo, salvos]);

    return (
        <>
            <Row className="mt-3 mb-2">
                <Col>
                    <InputGroup className="mx-2">
                        <Form.Control size="lg" placeholder="Nome do cargo" onChange={(event) => handleCargoChange(event.target.value)}/>
                    </InputGroup>
                </Col>
                <Col>
                    <SearchField title="Cidade, estado, região ou 'Brasil'" searchEndpoint={'editais/localização/lista'} onSearchChange={handleSearchChange}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div class="d-flex align-items-center mb-2">
                        <DropdownButton id="dropdown-anuncio" className="mx-1" title={dataAnúncio ? dataAnúncio : "Data do anúncio"} variant={dataAnúncio ? "success" : "secondary"} size="sm">
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("dataAnúncio", "hoje")}>hoje</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("dataAnúncio", "últimos 7 dias")}>últimos 7 dias</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("dataAnúncio", "últimos 30 dias")}>últimos 30 dias</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("dataAnúncio", "último ano")}>último ano</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("dataAnúncio", null)}>Limpar</Dropdown.Item>
                        </DropdownButton>
                        <DropdownButton id="dropdown-nivel" className="mx-1" title={nível ? getNomeNível(nível) : "Nível de escolaridade"} variant={nível ? "success" : "secondary"} size="sm">
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("nível", 1)}>Ensino médio</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("nível", 2)}>Ensino superior</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("nível", null)}>Limpar</Dropdown.Item>
                        </DropdownButton>
                        <DropdownButton id="dropdown-carga-horaria" className="mx-1" title={cargaHorária ? `${cargaHorária} horas semanais` : "Carga horária"} variant={cargaHorária ? "success" : "secondary"} size="sm">
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("cargaHorária", 20)}>20 horas semanais</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("cargaHorária", 30)}>30 horas semanais</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("cargaHorária", 40)}>40 horas semanais</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("cargaHorária", 44)}>44 horas semanais</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("cargaHorária", null)}>Limpar</Dropdown.Item>
                        </DropdownButton>
                        <DropdownButton id="dropdown-tipo" className="mx-1 text-capitalize" title={tipo ? tipo : "Tipo"} variant={tipo ? "success" : "secondary"} size="sm">
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("tipo", "federal")}>Federal</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("tipo", "estadual")}>Estadual</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("tipo", "municipal")}>Municipal</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item as="button" onClick={() => handleFilterChange("tipo", null)}>Limpar</Dropdown.Item>
                        </DropdownButton>
                        {logado &&
                        <Button id="dropdown-salvos" className="mx-1" variant={salvos ? "success" : "secondary"} onClick={() => handleFilterChange("salvos", !salvos)} size="sm">
                            Apenas salvos
                        </Button>
                        }
                    </div>
                </Col>
            </Row>
        </>
    );
};