// import logo from './logo.svg';
// import './App.css';
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Alert, Container, Row, Col, Form, Button, FloatingLabel } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import NavigationBar from "../components/NavigationBar";
import AuthService from "../services/AuthService";
import { Routes, Route, useParams } from 'react-router-dom';


function Ativar() {
    const [activated, setActivated] = useState(false);
    const [solicitarAtivar, setSolicitarAtivar] = useState(false);
    const [error, setError] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    let {uid, token} = useParams();

    useEffect(() => {
      if (uid && token){
        AuthService.activate(uid, token).then(response => {
          setActivated(true);
        }).catch(err => {
          console.log(err);
          setError('Erro ao ativar conta');
        })
      } else {
        setSolicitarAtivar(true);
      }
    }, []);

    return (
      <>
        <NavigationBar logado={false}/>
        <Container fluid className="d-flex align-items-center justify-content-center" style={{ height: '80vh' }}>
          <Row>
            <Col xs={12} md={12} lg={12}>
              {error && <Alert variant="danger" size="small">{error}</Alert>}
              {activated && <Alert variant="success" size="small">Conta ativada com sucesso! Entre com seu email e senha novamente.</Alert>}
              {solicitarAtivar && 
                <Alert variant="info" size="small">
                  Um email foi enviado para você com um link para ativar sua conta.<br/>
                  Após ativar sua conta, você poderá acessar o site.<br/>
                  Verifique sua caixa de entrada e sua caixa de spam.
                  </Alert>
              }
            </Col>
          </Row>
        </Container>
      </>
    
  )}

export default Ativar;
