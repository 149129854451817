import axios from "axios";

const api = axios.create({
  baseURL: (process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:8000/') + "api/",
});

// Adiciona um interceptor de requisição
api.interceptors.request.use((config) => {
  // Obtém o token do local onde está armazenado
  const token = localStorage.getItem('@radar_concursos:access_token');

  // Se o token existir, adiciona ao cabeçalho Authorization
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

// Adiciona um interceptor de resposta
api.interceptors.response.use((response) => {
    return response;
  }, async (error) => {
    const originalRequest = error.config;

    // Verifica se o erro é de token inválido ou expirado
    if (error.response.status === 401 && error.response.data.code === 'token_not_valid') {
      // Verifica se a requisição é para o endpoint de refresh
      if (originalRequest.url === '/token/refresh') {
        // Se for, retorna a rejeição do erro diretamente
        localStorage.removeItem('@radar_concursos:access_token');
        localStorage.removeItem('@radar_concursos:refresh_token');
        // return api(originalRequest) || Promise.reject(error);
        return Promise.reject(error);
      }
      
      try {
        // Tenta atualizar o token
        const response = await api.post('/token/refresh', { refresh: localStorage.getItem('@radar_concursos:refresh_token') });

        // Atualiza os tokens no local storage
        localStorage.setItem('@radar_concursos:access_token', response.data.access);
        localStorage.setItem('@radar_concursos:refresh_token', response.data.refresh);

        // Tenta a requisição novamente com o novo token
        originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access;
        return api(originalRequest);
      } catch (err) {
        console.log(err);
        // Trata o erro de atualização do token como achar melhor
      }
    }
    return Promise.reject(error);
});

export default api;