import Axios from "axios";
import React, { useEffect, useState } from "react"
import TimeAgo from 'react-time-ago';
import api from "../services/api"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins, faBriefcase, faPersonCirclePlus, faBusinessTime, faCheck, faClock, faBookmark, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

export default function JobDetail({ jobData, logado, onSaved, mostrarLink = false }) {
    // const { token, user } = useAuth();
    // const [error, setError] = useState([]);

    function salvarCargoHandler(){
        api.post(`editais/cargos/${jobData.id}/salvar`).then(response => {
            onSaved();
        }).catch(err => {
            console.log(err);
            // setError('Erro ao carregar endereço');
        })
    }

    function getLocalidade(){
        return jobData.edital.instituição.municipio ? (`Municipal: ${jobData.edital.instituição.municipio.nome}`) : (`Estadual: ${jobData.edital.instituição.estado.nome}`);
    }

    function getCargoURL(jobData){
        return `/cargo/${jobData.id}/${jobData.slug}`;
    }

    function getCargoTitulo(jobData){
        if (mostrarLink){
            return <Link to={getCargoURL(jobData)}>{jobData.nome}</Link>;
        } else {
            return jobData.nome;
        }
    }

    function renderizarBotaoSalvar() {
        const salvarText = jobData.salvo ? "Salvo" : "Salvar";
        const salvarButtonClass = jobData.salvo ? "btn btn-success mx-1 my-1" : "btn btn-outline-success mx-1 my-1";
    
        if (!logado) {
            return null;
        }
    
        return (
            <button type="button" className={salvarButtonClass} onClick={salvarCargoHandler}>
                <FontAwesomeIcon icon={faBookmark}/> {salvarText}
            </button>
        );
    }

    return jobData && (
        <div className="card col overflow-auto" style={{"maxHeight": "100vh"}}>
            <div className="card-body">
                <h5 className="card-title fw-semibold text-uppercase mt-3">
                    {getCargoTitulo(jobData)}
                </h5>
                <h6 className="card-subtitle mb-2 text-body-secondary small">
                    {/* <span>{getLocalidade()}</span> */}
                    <span>{jobData.edital.instituição.nome}</span>
                    <span className="mx-2">·</span>
                    <span>publicado <TimeAgo date={Date.parse(jobData.criado_em)} locale='pt'/></span>
                    <span className="mx-2">·</span>
                    <span>atualizado <TimeAgo date={Date.parse(jobData.editado_em)} locale='pt'/></span>
                </h6>
                <p className="text-muted"><small>Verifique o edital e suas retificações para a informação mais precisa.</small></p>
                <p>Inscrições de {new Date(jobData.data_inicio_inscricoes).toLocaleDateString('pt-BR')} até {new Date(jobData.data_fim_inscricoes).toLocaleDateString('pt-BR')}</p>
                {jobData.data_prova ? <p>Data prevista da prova: {new Date(jobData.data_prova).toLocaleDateString('pt-BR')}</p> : ""}

                <p><FontAwesomeIcon icon={faCoins}/> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(jobData.vencimento_basico)}</p>
                <p><FontAwesomeIcon icon={faBriefcase} /> {jobData.numero_vagas_ac} vagas de ampla concorrência</p>
                {jobData.numero_vagas_pcd > 0 &&
                    <p><FontAwesomeIcon icon={faPersonCirclePlus} /> {jobData.numero_vagas_pcd} vagas para pessoas com deficiência</p>
                } 
                <p><FontAwesomeIcon icon={faBusinessTime} /> {jobData.numero_vagas_reserva} vagas de cadastro de reserva</p>
                {jobData.adicional > 0 &&
                    <p><FontAwesomeIcon icon={faCheck} /> Adicional</p>
                }
                {jobData.vantagens > 0 &&
                    <p><FontAwesomeIcon icon={faCheck} /> Vantagens</p>
                }
                <p><FontAwesomeIcon icon={faClock} /> {jobData.carga_horaria} horas semanais</p>

                <a href={jobData.edital.link} className="btn btn-outline-primary mx-1 my-1" role="button"><FontAwesomeIcon icon={faArrowUpRightFromSquare} /> Ver edital</a>
                {renderizarBotaoSalvar()}
            </div>
        </div>
    )

}