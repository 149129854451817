import React, { useEffect, useState } from "react"
// import ReactSearchBox from "react-search-box";
import { Navbar, Nav, Form, FormControl, Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBriefcase, faUser, faBookmark } from '@fortawesome/free-solid-svg-icons';
import api from "../services/api"
import AuthService from "../services/AuthService"

export default function NavigationBar({ logado, children }) {
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    setLogged(AuthService.isAuthenticated());
  }, []);

    return (
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/" className="ms-4">
          <img
            src="/logo.svg"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="ConcursoPubli.co"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <Nav>
            {/* <Link to="/" className="nav-link icon-text text-center">
              <Row>
                <Col xs={12}><FontAwesomeIcon icon={faHome} /></Col>
                <Col xs={12}><span>Início</span></Col>
              </Row>
            </Link> */}
            <Link to="/cargos" className="nav-link icon-text text-center">
              <Row>
                <Col xs={12}><FontAwesomeIcon icon={faBriefcase} /></Col>
                <Col xs={12}><span>Vagas</span></Col>
              </Row>
            </Link>
          </Nav>
          { logged &&
            <>
              {/* <Nav>
                <Nav.Link href="#perfil" className="icon-text text-center">
                  <Row>
                    <Col xs={12}><FontAwesomeIcon icon={faUser} /></Col>
                    <Col xs={12}><span>Meu Perfil</span></Col>
                  </Row>
                </Nav.Link>
              </Nav> */}
              <Nav>
                <Nav.Link href="/sair" className="icon-text text-center">
                  <Row>
                    <Col xs={12}><FontAwesomeIcon icon={faUser} /></Col>
                    <Col xs={12}><span>Sair</span></Col>
                  </Row>
                </Nav.Link>
              </Nav>
            </>
          }
          { !logged &&
            <>
              <Nav>
                <Nav.Link href="/registrar" className="icon-text text-center">
                  <Row>
                    <Col xs={12}><FontAwesomeIcon icon={faUser} /></Col>
                    <Col xs={12}><span>Registrar</span></Col>
                  </Row>
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link href="/entrar" className="icon-text text-center">
                  <Row>
                    <Col xs={12}><FontAwesomeIcon icon={faUser} /></Col>
                    <Col xs={12}><span>Entrar</span></Col>
                  </Row>
                </Nav.Link>
              </Nav>
            </>
          }
        </Navbar.Collapse>
      </Navbar>
    );

};