import React, { useEffect, useState } from "react"
import Pagination from 'react-bootstrap/Pagination';

export default function Jobs({ currentPage, totalPages, onPageChange, children }) {
    function handlePagePress(pageNumber){
        onPageChange(pageNumber);
    }

    return (
        <div className="card col px-4 overflow-auto me-2" style={{"maxHeight": "100vh"}}>
            <div className="card-body px-1">
                {children}
            </div>
            <Pagination className="align-items-center justify-content-center">
                <Pagination.Item
                    disabled={currentPage === 1}
                    onClick={() => handlePagePress(currentPage - 1)}
                >« Anterior</Pagination.Item>
                <Pagination.Item
                    disabled={currentPage === totalPages}
                    onClick={() => handlePagePress(currentPage + 1)}
                >Próximo »</Pagination.Item>
            </Pagination>
        </div>
    );

};