import React from "react";
import {
  createBrowserRouter
} from "react-router-dom";

import Landing from "./pages/Landing";
import Cargos from "./pages/Cargos";
import Cargo from "./pages/Cargo";
import Login from "./pages/Login";
import Ativar from "./pages/Ativar";
import Registrar from "./pages/Registrar";
import Sair from "./pages/Sair";

const router = createBrowserRouter([
  { path: "/cargo/:id/:slug", Component: Cargo },
  { path: "/cargo/:id", Component: Cargo },
  { path: "/cargos", Component: Cargos },
  { path: "/entrar", Component: Login },
  { path: "/ativar/:uid/:token", Component: Ativar },
  { path: "/ativar/", Component: Ativar },
  { path: "/registrar", Component: Registrar },
  { path: "/sair", Component: Sair },
  { path: "*", Component: Landing },
]);

export default router;
