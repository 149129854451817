// import logo from './logo.svg';
// import './App.css';
import React, { useCallback, useEffect, useRef, useState } from "react";

import api from "../services/api"
import { Container, Row, Col, Form, Button, FloatingLabel } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';


function Sair() {
    const navigate = useNavigate();
    const location = useLocation();

    function deslogar(){
      console.log("Deslogando...")
      localStorage.removeItem("@radar_concursos:access_token");
      localStorage.removeItem("@radar_concursos:refresh_token");

      navigate(location?.state?.from || '/cargos');
    }

    useEffect(() => {
      deslogar();
    }, []);

    return (
      <>
      </>
    
  )}

export default Sair;
