// import logo from './logo.svg';
// import './App.css';
import React, { useCallback, useEffect, useRef, useState } from "react";

import api from "../services/api"
import { Container, Row, Col, Form, Button, FloatingLabel } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import NavigationBar from "../components/NavigationBar";
import AuthService from "../services/AuthService";


function Registrar() {
    const [logado, setLogado] = useState(false);
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState(null);
    const [repeatPassword, setRepeatPassword] = useState(null);
    const [error, setError] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    function handleRegisterClick(event){
      event.preventDefault();

      if (password !== repeatPassword) {
        setError("Erro ao criar a conta: As senhas não coincidem.");
        return;
      }

      AuthService.register(email, password).then(response => {
        var response_data = response.data;

        if (response_data["error"]){
          setError(response_data["error"].join(" "));
          return;
        }

        navigate(location?.state?.from || '/ativar');

      }).catch(err => {
        console.log(err);
        setError("Erro ao criar a conta: " + err.response.data.error[0]);
      })
    }

    useEffect(() => {
      if (localStorage.getItem("@radar_concursos:access_token") && localStorage.getItem("@radar_concursos:refresh_token")){
        navigate(location?.state?.from || '/cargos');
      }
    }, []);

    return (
      <>
        <NavigationBar logado={false}/>
        <Container fluid className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Form>
                <h3 className="text-center mb-4">Registrar</h3>
                <FloatingLabel controlId="floatingInput" label="Nome" className="mb-3">
                  <Form.Control type="email" placeholder="Digite seu nome" onChange={(e) => {setName(e.target.value)}} />
                </FloatingLabel>

                <FloatingLabel controlId="floatingInput" label="E-mail" className="mb-3">
                  <Form.Control type="email" placeholder="Digite seu e-mail" onChange={(e) => {setEmail(e.target.value)}} />
                </FloatingLabel>

                <FloatingLabel controlId="floatingPassword" label="Senha" className="mb-3">
                  <Form.Control type="password" placeholder="Digite sua senha" onChange={(e) => {setPassword(e.target.value)}}/>
                </FloatingLabel>

                <FloatingLabel controlId="floatingPassword" label="Repita a senha" className="mb-3">
                  <Form.Control type="password" placeholder="Repita sua senha" onChange={(e) => {setRepeatPassword(e.target.value)}} />
                </FloatingLabel>

                {error && <p className="text-danger">{error}</p>}
                <Button variant="primary" type="submit" className="w-100 mt-3" onClick={handleRegisterClick}>
                  Registrar
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    
  )}

export default Registrar;