// import logo from './logo.svg';
// import './App.css';
import React, { useCallback, useEffect, useRef, useState } from "react";

import api from "../services/api"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import NavigationBar from "../components/NavigationBar";
import Job from "../components/Job";
import JobDetail from "../components/JobDetail";
import Jobs from "../components/Jobs";
import FilterJobs from "../components/FilterJobs";


function Cargos() {
    const [cargos, setCargos] = useState([]);
    const [logado, setLogado] = useState(false);
    const [cargoSelecionado, setCargoSelecionado] = useState(null);
    const [currentFiltros, setCurrentFiltros] = useState({});
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);

    function fetchCargos(){
      console.log(currentPage)
      const listParams = { ...currentFiltros, page: currentPage };

      api.get("editais/cargos/lista", { params: listParams }).then(response => {
        setCargos(response.data.cargos);
        setLogado(response.data.usuario_logado);
        setCurrentPage(response.data.pagina);
        setTotalPages(response.data.total_paginas);

      }).catch(err => {
        console.log(err);
        setError('Erro ao carregar endereço');
      })
    }

    function updateCargoAtual(cargo){
      api.get(`editais/cargos/${cargo.id}`).then(response => {
        setCargoSelecionado(response.data);
      }).catch(err => {
        console.log(err);
        setError('Erro ao carregar endereço');
      })
    }

    function filterJobs(filters){
      setCurrentFiltros(filters);
    }

    function handlePageChange(pageSelected){
      setCurrentPage(pageSelected);
    }

    useEffect(() => {
      fetchCargos();
    }, [currentFiltros, currentPage]);

    useEffect(() => {
      if (cargos.length > 0){
        updateCargoAtual(cargos[0]);
      }
    }, [cargos]);

    return (
      <>
        <NavigationBar logado={logado}/>
        <Container>
          <Row>
              <FilterJobs onFilterChange={filterJobs} logado={logado}/>
          </Row>
          <Row className="mt-2">
            <Jobs currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange}>
              {cargos && cargos.map((cargo, index) => (
                <Row key={index}>
                  <Job key={cargo.id} jobData={cargo} isSelected={cargo.id === cargoSelecionado?.id} onClick={() => updateCargoAtual(cargo)}/>
                </Row>
              ))}
            </Jobs>
            <JobDetail jobData={cargoSelecionado} logado={logado} mostrarLink={true} onSaved={() => updateCargoAtual(cargoSelecionado)}/>
          </Row>
        </Container>
      </>
    
  )}

export default Cargos;
