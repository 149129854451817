import api from './api'; // Importe sua configuração de API

const login = (email, password) => {
    return api.post("token/pair", {"email": email, "password": password}).then(response => {
        var response_data = response.data;
        
        localStorage.setItem("@radar_concursos:access_token", response_data["access"]);
        localStorage.setItem("@radar_concursos:refresh_token", response_data["refresh"]);
        return response; // Retorna os dados para encadeamento adicional
    });
};

const register = (email, password) => {
    return api.post("perfil/create", {"email": email, "password": password});
};

const isAuthenticated = () => {
    const accessToken = localStorage.getItem("@radar_concursos:access_token");
    const refreshToken = localStorage.getItem("@radar_concursos:refresh_token");
    return (accessToken && refreshToken);
};

const activate = (uid, token) => {
    return api.get(`perfil/ativar/${uid}/${token}`);
};

export default { login, register, isAuthenticated, activate };