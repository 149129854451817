import React, { useState, useEffect, Component } from 'react';
import { Button, OverlayTrigger, Popover, FormControl, FloatingLabel, Form } from 'react-bootstrap';
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import api from "../services/api"

function SearchField( {  onSearchChange, title, searchEndpoint, icon}) {
  const [localizações, setLocalizações] = useState([]);
  const [carregando, setCarregando] = useState(false);
  const [onde, setOnde] = useState([]);

  const handleSearch = (query) => {
    setCarregando(true);

    api.get(`${searchEndpoint}?q=${query}`)
      .then((resp) => resp.data)
      .then((items) => {
        setLocalizações(items);
        setCarregando(false);
      })
      .catch((error) => {
        console.error(error);
        setCarregando(false);
      });
  };

  useEffect(() => {
    if (onde){
      onSearchChange(onde[0])
    }
  }, [onde, onSearchChange]);

  const filterBy = () => true;

  return (
    <Form.Group>
        <AsyncTypeahead
        clearButton
        filterBy={filterBy}
        id="basic-typeahead-single"
        labelKey="name"
        onChange={setOnde}
        options={localizações}
        placeholder={title}
        selected={onde}
        isLoading={carregando}
        onSearch={handleSearch}
        size={'lg'}
        />
    </Form.Group>
  );
}

export default SearchField;